import Vue from 'vue'
export default {
  parseDeviceData: function (data) {
    let firmwareVersion = data.firmware_version
      // CHECK - deviceType
    let deviceTypeName = 'unknown'
    if (data.type == '11') {
      try {
        firmwareVersion = Vue.tool.parseToJSON(firmwareVersion)['bridge']
      } catch (err) {
        console.error(err)
      }
      deviceTypeName = 'bridge'
    }
    else if (data.type == '12') {
      deviceTypeName = 'camera'
    }
    // CHECK - zoneType
    let zoneTypeName = 'unknown'
    switch (data.sensor.zone_type) {
    case 0:
      zoneTypeName = 'entry'
      break
    case 1:
      zoneTypeName = 'follower'
      break
    case 2:
      zoneTypeName = 'instant'
      break
    case 3:
      zoneTypeName = '24hour'
      break
    case 4:
      zoneTypeName = 'instant' // exterior
      break
    case 5:
      zoneTypeName = 'entry' // exterior
      break
    case 21:
      zoneTypeName = 'intercom armed'
      break
    case 22:
      zoneTypeName = 'intercom 24h'
      break
    case 100:
      zoneTypeName = 'live video only' 
      break
    }
    return {
      deviceId: data.device_id,
      deviceName: data.name,
      deviceLinkedDevices: data.linked_devices,
      apiType: data.api_type,
      videoProfileToken: data?.video_profile_token,
      siteInfo: {
        siteId: data.site_id
      },
      planInfo: {
        isLiveEnabled: !!data.is_live_enabled,
        isEventEnabled: !!data.is_event_enabled,
        isPlaybackEnabled: data.is_playback_enabled === undefined ? data.is_live_enabled && data.is_event_enabled : !!data.is_playback_enabled,
        isSpeakerEnabled: !!data.is_speaker_enabled,
        isONVIFEventEnabled: !!data.is_onvif_event_enabled,
      },
      networkInfo: {
        isOnline: data.is_online,
        isOnlineUpdatedDate: data.is_online_update_time ? new Date(data.is_online_update_time) : null,
        lastOnlineDate: data.online_timestamp ? new Date(1000 * data.online_timestamp) : null,
        lastOfflineDate: data.offline_timestamp ? new Date(1000 * data.offline_timestamp) : null,
        lastCloudAccessDate: data.last_access_timestamp ? new Date(1000 * data.last_access_timestamp) : null,
        publicIp: data.public_ip,
        privateIp: data.private_ip,
        mqttUrl: data.mqtt_url,
        streamUrl: data.stream_url,
        snapshotUrl: data.snapshot_url
      },
      hardwareInfo: {
        firmwareUpdateStatus: data.firmware_update_status,
        firmwareVersion: firmwareVersion ? firmwareVersion : data.firmware_version,
        firmwareVersionToPadding: data.type === 11 ? Vue.tool.toComparableFWVersion(firmwareVersion) : null,
        macAddress: data.mac,
        channel: data.channel,
        bridgeChannel: data.group_channel,
        bridgeId: data.group_id,
        deviceType: data.type, // 11: bridge, 12: camera(mounted in bridge), 10: chekt camera(deprecated)
        deviceTypeName: deviceTypeName,
        deviceModel: data.model
      },
      zoneInfo: {
        alarm: data.alarm_zone,
        trouble: data.trouble_zone,
        virtual: data.virtual_zone,
        armed: data.arming_state == 'active',
        zoneType: data?.sensor?.zone_type,
        zoneTypeName: zoneTypeName,
        state: data.di_state,
      },
      metacameraDataInfo: {
        sensors: data.sensors_meta,
        position: data.position_metadata,
        status: data.status_meta
      },
      privacyInfo: {
        isEnabled: !!data.is_privacy,// !!: 데이터를 true/false로 바꿔주는 것
        backgroundImage: data.privacy_background_image_url,
        disabledTimestamp: data.privacy_disabled_timestamp,
        disabledDuration: data.privacy_disabled_duration
      },
      notificationInfo: {
        isEnabledWhenDisarmed: false,
        isEnabledWhenArmed: false,
        isEnabledWhenStay: true,
        isEnabledWhenAway: false
      },
      statusInfo: {
        isActivated: data.is_activated,
        isRegistered: data.is_registered,
        createdDate: data.created ? new Date(data.created) : null,
        isContinuousRecordingEnabled: data.is_cont_recording,
        mountType: data.mount_type,
      },
      statistics: {
        totalShareLinkCreate: data.psap_create_count ? data.psap_create_count : 0,
        totalSnapshotRequest: data.snapshot_req_count ? data.snapshot_req_count : 0,
        totalVerificationRequest: data.verification_req_count ? data.verification_req_count : 0,
        totalVideoRequest: data.video_req_count ? data.video_req_count : 0,
        totalEvent: data.event_count ? data.event_count : 0,
        totalArm: data.arm_count ? data.arm_count : 0,
        totalDisarmRequest: data.disarm_count ? data.disarm_count : 0,
        totalOnline: data.online_count ? data.online_count : 0,
        totalOffline: data.offline_count ? data.offline_count : 0,
        totalEventCheck: data.event_check_count ? data.event_check_count : 0,
        totalSiteEventCheck: data.site_event_check_count ? data.site_event_check_count : 0
      },
      capabilities: {
        isMicEnabled: !!data.is_mic_enabled,
        isSpeakerEnabled: !!data.is_speaker_enabled,
        isPtzEnabled: !!data.is_ptz_enabled,
      }
    }
  },
}
