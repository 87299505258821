//////////
// CORE //
//////////
export const RESET_STATE = 'RESET_STATE'
export const PROCESS_API_SUCCESS = 'PROCESS_API_SUCCESS'
export const PROCESS_API_FAILURE = 'PROCESS_API_FAILURE'

///////////
// ROUTE //
///////////
export const SET_TABS_COLOR = 'SET_TABS_COLOR'
export const IS_ROUTER_CHANGE = 'IS_ROUTER_CHANGE'

//////////
// PUSH //
//////////
export const REQUEST_PUSH_INIT = 'REQUEST_PUSH_INIT'
export const REQUEST_RESET_PUSH_INSTANCE = 'REQUEST_RESET_PUSH_INSTANCE'

/////////////////////
// CAMERAS_PER_ROW //
/////////////////////

export const CHANGE_CAMERAS_PER_ROW = 'CHANGE_CAMERAS_PER_ROW'
export const UPDATE_PREFER_NO_NAME = 'UPDATE_PREFER_NO_NAME'

///////////////
// MYPROFILE //
///////////////
export const GET_MYPROFILE_REQUEST = 'GET_MYPROFILE_REQUEST'
export const GET_MYPROFILE_SUCCESS = 'GET_MYPROFILE_SUCCESS'
export const GET_MYPROFILE_FAILURE = 'GET_MYPROFILE_FAILURE'

export const UPDATE_MYPROFILE_REQUEST = 'UPDATE_MYPROFILE_REQUEST'
export const UPDATE_MYPROFILE_SUCCESS = 'UPDATE_MYPROFILE_SUCCESS'
export const UPDATE_MYPROFILE_FAILURE = 'UPDATE_MYPROFILE_FAILURE'

export const SEND_PASSWORDRESET_EMAIL_REQUEST = 'SEND_PASSWORDRESET_EMAIL_REQUEST'
export const SEND_PASSWORDRESET_EMAIL_SUCCESS = 'SEND_PASSWORDRESET_EMAIL_SUCCESS'
export const SEND_PASSWORDRESET_EMAIL_FAILURE = 'SEND_PASSWORDRESET_EMAIL_FAILURE'

export const SEND_EMAILCHANGE_EMAIL_REQUEST = 'SEND_EMAILCHANGE_EMAIL_REQUEST'
export const SEND_EMAILCHANGE_EMAIL_SUCCESS = 'SEND_EMAILCHANGE_EMAIL_SUCCESS'
export const SEND_EMAILCHANGE_EMAIL_FAILURE = 'SEND_EMAILCHANGE_EMAIL_FAILURE'

//////////
// SITE //
//////////
export const INIT_SELECTED_SITE = 'INIT_SELECTED_SITE'
export const SELECT_SITE = 'SELECT_SITE'

export const GET_SITES_REQUEST = 'GET_SITES_REQUEST'
export const GET_SITES_SUCCESS = 'GET_SITES_SUCCESS'
export const GET_SITES_FAILURE = 'GET_SITES_FAILURE'

export const GET_SITE_REQUEST = 'GET_SITE_REQUEST'
export const GET_SITE_SUCCESS = 'GET_SITE_SUCCESS'
export const GET_SITE_FAILURE = 'GET_SITE_FAILURE'

/////////////////
// SITE DEALER //
/////////////////
export const GET_SITE_DEALER_REQUEST = 'GET_SITE_DEALER_REQUEST'
export const GET_SITE_DEALER_SUCCESS = 'GET_SITE_DEALER_SUCCESS'
export const GET_SITE_DEALER_FAILURE = 'GET_SITE_DEALER_FAILURE'

/////////////////
// SITE DEVICE //
/////////////////
export const INIT_DEVICE_LIST = 'INIT_DEVICE_LIST'

export const GET_SITE_DEVICES_REQUEST = 'GET_SITE_DEVICES_REQUEST'
export const GET_SITE_DEVICES_SUCCESS = 'GET_SITE_DEVICES_SUCCESS'
export const GET_SITE_DEVICES_FAILURE = 'GET_SITE_DEVICES_FAILURE'

export const GET_SITE_DEVICE_REQUEST = 'GET_SITE_DEVICE_REQUEST'
export const GET_SITE_DEVICE_SUCCESS = 'GET_SITE_DEVICE_SUCCESS'
export const GET_SITE_DEVICE_FAILURE = 'GET_SITE_DEVICE_FAILURE'

export const UPDATE_SITE_DEVICE_PRIVACYMODE_REQUEST = 'UPDATE_SITE_DEVICE_PRIVACYMODE_REQUEST'
export const UPDATE_SITE_DEVICE_PRIVACYMODE_SUCCESS = 'UPDATE_SITE_DEVICE_PRIVACYMODE_SUCCESS'
export const UPDATE_SITE_DEVICE_PRIVACYMODE_FAILURE = 'UPDATE_SITE_DEVICE_PRIVACYMODE_FAILURE'

/////////////////
// SITE DELAYS //
/////////////////
export const GET_SITE_DELAYS_REQUEST = 'GET_SITE_DELAYS_REQUEST'
export const GET_SITE_DELAYS_SUCCESS = 'GET_SITE_DELAYS_SUCCESS'
export const GET_SITE_DELAYS_FAILURE = 'GET_SITE_DELAYS_FAILURE'

export const SET_SITE_ENTRY_DELAY_REQUEST = 'SET_SITE_ENTRY_DELAY_REQUEST'
export const SET_SITE_ENTRY_DELAY_SUCCESS = 'SET_SITE_ENTRY_DELAY_SUCCESS'
export const SET_SITE_ENTRY_DELAY_FAILURE = 'SET_SITE_ENTRY_DELAY_FAILURE'

export const SET_SITE_EXIT_DELAY_REQUEST = 'SET_SITE_EXIT_DELAY_REQUEST'
export const SET_SITE_EXIT_DELAY_SUCCESS = 'SET_SITE_EXIT_DELAY_SUCCESS'
export const SET_SITE_EXIT_DELAY_FAILURE = 'SET_SITE_EXIT_DELAY_FAILURE'

export const SET_SITE_BELL_DURATION_REQUEST = 'SET_SITE_BELL_DURATION_REQUEST'
export const SET_SITE_BELL_DURATION_SUCCESS = 'SET_SITE_BELL_DURATION_SUCCESS'
export const SET_SITE_BELL_DURATION_FAILURE = 'SET_SITE_BELL_DURATION_FAILURE'

export const SET_SITE_RECORDING_INTERVAL_REQUEST = 'SET_SITE_RECORDING_INTERVAL_REQUEST'
export const SET_SITE_RECORDING_INTERVAL_SUCCESS = 'SET_SITE_RECORDING_INTERVAL_SUCCESS'
export const SET_SITE_RECORDING_INTERVAL_FAILURE = 'SET_SITE_RECORDING_INTERVAL_FAILURE'

//////////////////////
// SITE ARMING MODE //
//////////////////////
export const SET_ARMING_MODE = 'SET_ARMING_MODE'

export const GET_SITE_ARMING_MODES_REQUEST = 'GET_SITE_ARMING_MODES_REQUEST'
export const GET_SITE_ARMING_MODES_SUCCESS = 'GET_SITE_ARMING_MODES_SUCCESS'
export const GET_SITE_ARMING_MODES_FAILURE = 'GET_SITE_ARMING_MODES_FAILURE'

export const SET_SITE_DISARMED_MODE_REQUEST = 'SET_SITE_DISARMED_MODE_REQUEST'
export const SET_SITE_DISARMED_MODE_SUCCESS = 'SET_SITE_DISARMED_MODE_SUCCESS'
export const SET_SITE_DISARMED_MODE_FAILURE = 'SET_SITE_DISARMED_MODE_FAILURE'

export const SET_SITE_ARMED_MODE_REQUEST = 'SET_SITE_ARMED_MODE_REQUEST'
export const SET_SITE_ARMED_MODE_SUCCESS = 'SET_SITE_ARMED_MODE_SUCCESS'
export const SET_SITE_ARMED_MODE_FAILURE = 'SET_SITE_ARMED_MODE_FAILURE'

export const SET_SITE_HOME_MODE_REQUEST = 'SET_SITE_HOME_MODE_REQUEST'
export const SET_SITE_HOME_MODE_SUCCESS = 'SET_SITE_HOME_MODE_SUCCESS'
export const SET_SITE_HOME_MODE_FAILURE = 'SET_SITE_HOME_MODE_FAILURE'

export const SET_SITE_AWAY_MODE_REQUEST = 'SET_SITE_AWAY_MODE_REQUEST'
export const SET_SITE_AWAY_MODE_SUCCESS = 'SET_SITE_AWAY_MODE_SUCCESS'
export const SET_SITE_AWAY_MODE_FAILURE = 'SET_SITE_AWAY_MODE_FAILURE'

////////////////
// SITE EVENT //
////////////////
export const INIT_SITE_EVENTS = 'INIT_SITE_EVENTS'

export const GET_SITE_EVENTS_REQUEST = 'GET_SITE_EVENTS_REQUEST'
export const GET_SITE_EVENTS_SUCCESS = 'GET_SITE_EVENTS_SUCCESS'
export const GET_SITE_EVENTS_FAILURE = 'GET_SITE_EVENTS_FAILURE'

export const GET_SITE_EVENT_REQUEST = 'GET_SITE_EVENT_REQUEST'
export const GET_SITE_EVENT_SUCCESS = 'GET_SITE_EVENT_SUCCESS'
export const GET_SITE_EVENT_FAILURE = 'GET_SITE_EVENT_FAILURE'

///////////////////////
// SITE ACTIVITY LOG //
///////////////////////
export const INIT_SITE_ACTIVITY_LOGS = 'INIT_SITE_ACTIVITY_LOGS'

export const GET_SITE_ACTIVITY_LOGS_REQUEST = 'GET_SITE_ACTIVITY_LOGS_REQUEST'
export const GET_SITE_ACTIVITY_LOGS_SUCCESS = 'GET_SITE_ACTIVITY_LOGS_SUCCESS'
export const GET_SITE_ACTIVITY_LOGS_FAILURE = 'GET_SITE_ACTIVITY_LOGS_FAILURE'

export const GET_SITE_ACTIVITY_LOG_AUDIO_URL_REQUEST = 'GET_SITE_ACTIVITY_LOG_AUDIO_URL_REQUEST'
export const GET_SITE_ACTIVITY_LOG_AUDIO_URL_SUCCESS = 'GET_SITE_ACTIVITY_LOG_AUDIO_URL_SUCCESS'
export const GET_SITE_ACTIVITY_LOG_AUDIO_URL_FAILURE = 'GET_SITE_ACTIVITY_LOG_AUDIO_URL_FAILURE'

///////////////////////
// SITE STATISTICS   //
///////////////////////

export const DATE_PICKER_DATE_CHANGED = 'DATE_PICKER_DATE_CHANGED'
export const DATE_PICKER_DATE_SELECTED = 'DATE_PICKER_DATE_SELECTED'

export const GET_SITE_EVENT_STATISTICS_REQUEST = 'GET_SITE_EVENT_STATISTICS_REQUEST'
export const GET_SITE_EVENT_STATISTICS_SUCCESS = 'GET_SITE_EVENT_STATISTICS_SUCCESS'
export const GET_SITE_EVENT_STATISTICS_FAILURE = 'GET_SITE_EVENT_STATISTICS_FAILURE'

export const GET_DEVICE_EVENT_STATISTICS_REQUEST = 'GET_DEVICE_EVENT_STATISTICS_REQUEST'
export const GET_DEVICE_EVENT_STATISTICS_SUCCESS = 'GET_DEVICE_EVENT_STATISTICS_SUCCESS'
export const GET_DEVICE_EVENT_STATISTICS_FAILURE = 'GET_DEVICE_EVENT_STATISTICS_FAILURE'

/////////////////////
// DEVICE SNAPSHOT //
/////////////////////
export const GET_DEVICE_SNAPSHOT_REQUEST = 'GET_DEVICE_SNAPSHOT_REQUEST'
export const GET_DEVICE_SNAPSHOT_SUCCESS = 'GET_DEVICE_SNAPSHOT_SUCCESS'
export const GET_DEVICE_SNAPSHOT_FAILURE = 'GET_DEVICE_SNAPSHOT_FAILURE'

//////////////////
// DEVICE EVENT //
//////////////////
export const GET_DEVICE_EVENTS_REQUEST = 'GET_DEVICE_EVENTS_REQUEST'
export const GET_DEVICE_EVENTS_SUCCESS = 'GET_DEVICE_EVENTS_SUCCESS'
export const GET_DEVICE_EVENTS_FAILURE = 'GET_DEVICE_EVENTS_FAILURE'

export const INIT_DEVICE_EVENTS = 'INIT_DEVICE_EVENTS'

//////////////////
// EVENT RECORD //
//////////////////
export const GET_EVENT_RECORDS_REQUEST = 'GET_EVENT_RECORDS_REQUEST'
export const GET_EVENT_RECORDS_SUCCESS = 'GET_EVENT_RECORDS_SUCCESS'
export const GET_EVENT_RECORDS_FAILURE = 'GET_EVENT_RECORDS_FAILURE'

//////////////////
// RECORD MEDIA //
//////////////////
export const GET_RECORD_MEDIA_REQUEST = 'GET_RECORD_MEDIA_REQUEST'
export const GET_RECORD_MEDIA_SUCCESS = 'GET_RECORD_MEDIA_SUCCESS'
export const GET_RECORD_MEDIA_FAILURE = 'GET_RECORD_MEDIA_FAILURE'

/////////
// TTS //
/////////
export const GET_SITE_ALL_TTS_REQUEST = 'GET_SITE_ALL_TTS_REQUEST'
export const GET_SITE_ALL_TTS_SUCCESS = 'GET_SITE_ALL_TTS_SUCCESS'
export const GET_SITE_ALL_TTS_FAILURE = 'GET_SITE_ALL_TTS_FAILURE'

export const SEND_SITE_TTS_REQUEST = 'SEND_SITE_TTS_REQUEST'
export const SEND_SITE_TTS_SUCCESS = 'SEND_SITE_TTS_SUCCESS'
export const SEND_SITE_TTS_FAILURE = 'SEND_SITE_TTS_FAILURE'

export const CREATE_AUDIO_UPLOAD_URL_REQUEST = 'CREATE_AUDIO_UPLOAD_URL_REQUEST'
export const CREATE_AUDIO_UPLOAD_URL_SUCCESS = 'CREATE_AUDIO_UPLOAD_URL_SUCCESS'
export const CREATE_AUDIO_UPLOAD_URL_FAILURE = 'CREATE_AUDIO_UPLOAD_URL_FAILURE'

export const UPDATE_AUDIO_DATA_TO_CLOUD_REQUEST = 'UPDATE_AUDIO_DATA_TO_CLOUD_REQUEST'
export const UPDATE_AUDIO_DATA_TO_CLOUD_SUCCESS = 'UPDATE_AUDIO_DATA_TO_CLOUD_SUCCESS'
export const UPDATE_AUDIO_DATA_TO_CLOUD_FAILURE = 'UPDATE_AUDIO_DATA_TO_CLOUD_FAILURE'

export const CHANGE_OPEN_TTS_MODAL = 'CHANGE_OPEN_TTS_MODAL'

///////////
// RELAY //
///////////
export const GET_SITE_ALL_RELAY_REQUEST = 'GET_SITE_ALL_RELAY_REQUEST'
export const GET_SITE_ALL_RELAY_SUCCESS = 'GET_SITE_ALL_RELAY_SUCCESS'
export const GET_SITE_ALL_RELAY_FAILURE = 'GET_SITE_ALL_RELAY_FAILURE'

export const GET_SITE_ALL_RELAY_STATUS_REQUEST = 'GET_SITE_ALL_RELAY_STATUS_REQUEST'
export const GET_SITE_ALL_RELAY_STATUS_SUCCESS = 'GET_SITE_ALL_RELAY_STATUS_SUCCESS'
export const GET_SITE_ALL_RELAY_STATUS_FAILURE = 'GET_SITE_ALL_RELAY_STATUS_FAILURE'

export const CONTROL_SITE_RELAY_REQUEST = 'CONTROL_SITE_RELAY_REQUEST'
export const CONTROL_SITE_RELAY_SUCCESS = 'CONTROL_SITE_RELAY_SUCCESS'
export const CONTROL_SITE_RELAY_FAILURE = 'CONTROL_SITE_RELAY_FAILURE'

/////////////////
// SITE MEMBER //
/////////////////
export const GET_SITE_MEMBERS_REQUEST = 'GET_SITE_MEMBERS_REQUEST'
export const GET_SITE_MEMBERS_SUCCESS = 'GET_SITE_MEMBERS_SUCCESS'
export const GET_SITE_MEMBERS_FAILURE = 'GET_SITE_MEMBERS_FAILURE'

export const GET_SITE_MEMBER_REQUEST = 'GET_SITE_MEMBER_REQUEST'
export const GET_SITE_MEMBER_SUCCESS = 'GET_SITE_MEMBER_SUCCESS'
export const GET_SITE_MEMBER_FAILURE = 'GET_SITE_MEMBER_FAILURE'

export const CREATE_SITE_MEMBER_REQUEST = 'CREATE_SITE_MEMBER_REQUEST'
export const CREATE_SITE_MEMBER_SUCCESS = 'CREATE_SITE_MEMBER_SUCCESS'
export const CREATE_SITE_MEMBER_FAILURE = 'CREATE_SITE_MEMBER_FAILURE'

export const DELETE_SITE_MEMBER_REQUEST = 'DELETE_SITE_MEMBER_REQUEST'
export const DELETE_SITE_MEMBER_SUCCESS = 'DELETE_SITE_MEMBER_SUCCESS'
export const DELETE_SITE_MEMBER_FAILURE = 'DELETE_SITE_MEMBER_FAILURE'

export const UPDATE_SITE_MEMBER_REQUEST = 'UPDATE_SITE_MEMBER_REQUEST'
export const UPDATE_SITE_MEMBER_SUCCESS = 'UPDATE_SITE_MEMBER_SUCCESS'
export const UPDATE_SITE_MEMBER_FAILURE = 'UPDATE_SITE_MEMBER_FAILURE'

export const GET_MY_NOTIFICATION_STATUS_REQUEST = 'GET_MY_NOTIFICATION_STATUS_REQUEST'
export const GET_MY_NOTIFICATION_STATUS_SUCCESS = 'GET_MY_NOTIFICATION_STATUS_SUCCESS'
export const GET_MY_NOTIFICATION_STATUS_FAILURE = 'GET_MY_NOTIFICATION_STATUS_FAILURE'

export const GET_MY_ALL_DEVICE_NOTIFICATION_STATUS_REQUEST = 'GET_MY_ALL_DEVICE_NOTIFICATION_STATUS_REQUEST'
export const GET_MY_ALL_DEVICE_NOTIFICATION_STATUS_SUCCESS = 'GET_MY_ALL_DEVICE_NOTIFICATION_STATUS_SUCCESS'
export const GET_MY_ALL_DEVICE_NOTIFICATION_STATUS_FAILURE = 'GET_MY_ALL_DEVICE_NOTIFICATION_STATUS_FAILURE'

export const GET_MY_DEVICE_NOTIFICATION_STATUS_REQUEST = 'GET_MY_DEVICE_NOTIFICATION_STATUS_REQUEST'
export const GET_MY_DEVICE_NOTIFICATION_STATUS_SUCCESS = 'GET_MY_DEVICE_NOTIFICATION_STATUS_SUCCESS'
export const GET_MY_DEVICE_NOTIFICATION_STATUS_FAILURE = 'GET_MY_DEVICE_NOTIFICATION_STATUS_FAILURE'

export const UPDATE_MY_NOTIFICATION_STATUS_REQUEST = 'UPDATE_MY_NOTIFICATION_STATUS_REQUEST'
export const UPDATE_MY_NOTIFICATION_STATUS_SUCCESS = 'UPDATE_MY_NOTIFICATION_STATUS_SUCCESS'
export const UPDATE_MY_NOTIFICATION_STATUS_FAILURE = 'UPDATE_MY_NOTIFICATION_STATUS_FAILURE'

export const UPDATE_MY_DEVICE_NOTIFICATION_STATUS_REQUEST = 'UPDATE_MY_DEVICE_NOTIFICATION_STATUS_REQUEST'
export const UPDATE_MY_DEVICE_NOTIFICATION_STATUS_SUCCESS = 'UPDATE_MY_DEVICE_NOTIFICATION_STATUS_SUCCESS'
export const UPDATE_MY_DEVICE_NOTIFICATION_STATUS_FAILURE = 'UPDATE_MY_DEVICE_NOTIFICATION_STATUS_FAILURE'

export const SEND_INVITE_EMAIL_TO_SITE_MEMBER_REQUEST = 'SEND_INVITE_EMAIL_TO_SITE_MEMBER_REQUEST'
export const SEND_INVITE_EMAIL_TO_SITE_MEMBER_SUCCESS = 'SEND_INVITE_EMAIL_TO_SITE_MEMBER_SUCCESS'
export const SEND_INVITE_EMAIL_TO_SITE_MEMBER_FAILURE = 'SEND_INVITE_EMAIL_TO_SITE_MEMBER_FAILURE'


//////////////
// SECURITY //
//////////////
export const GET_SITE_SECURITY_REQUEST = 'GET_SITE_SECURITY_REQUEST'
export const GET_SITE_SECURITY_SUCCESS = 'GET_SITE_SECURITY_SUCCESS'
export const GET_SITE_SECURITY_FAILURE = 'GET_SITE_SECURITY_FAILURE'

export const GET_SITES_SECURITY_REQUEST = 'GET_SITES_SECURITY_REQUEST'
export const GET_SITES_SECURITY_SUCCESS = 'GET_SITES_SECURITY_SUCCESS'
export const GET_SITES_SECURITY_FAILURE = 'GET_SITES_SECURITY_FAILURE'

export const UPDATE_SITE_SECURITY_REQUEST = 'UPDATE_SITE_SECURITY_REQUEST'
export const UPDATE_SITE_SECURITY_SUCCESS = 'UPDATE_SITE_SECURITY_SUCCESS'
export const UPDATE_SITE_SECURITY_FAILURE = 'UPDATE_SITE_SECURITY_FAILURE'

export const ARMING_BUTTON_CLICKED = 'ARMING_BUTTON_CLICKED'


//////////
// AUTH //
//////////
export const AUTH_INIT_AUTH0          = 'AUTH_INIT_AUTH0'
export const AUTH_CLEAR_SESSION       = 'AUTH_CLEAR_SESSION'
export const AUTH_UPDATE_LAST_SESSION = 'AUTH_UPDATE_LAST_SESSION'

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE'

export const AUTH_REFRESH_TOKENS_REQUEST = 'AUTH_REFRESH_TOKENS_REQUEST'
export const AUTH_REFRESH_TOKENS_SUCCESS = 'AUTH_REFRESH_TOKENS_SUCCESS'
export const AUTH_REFRESH_TOKENS_FAILURE = 'AUTH_REFRESH_TOKENS_FAILURE'

///////////////////
// AUTHORIZATION //
///////////////////
export const REQUEST_CHECK_PERMISSION = 'REQUEST_CHECK_PERMISSION'

////////////
// LOGOUT //
////////////
export const REQUEST_LOGOUT = 'REQUEST_LOGOUT'

/////////////////////////////
// Control - Modal Overlay //
/////////////////////////////
export const SET_MODAL_OVERLAID = 'SET_MODAL_OVERLAID'
export const UNSET_MODAL_OVERLAID = 'UNSET_MODAL_OVERLAID'

//////////////////////////////////
// Control - Event Video Viewer //
//////////////////////////////////
export const OPEN_EVENT_VIDEO_VIEWER = 'OPEN_EVENT_VIDEO_VIEWER'

export const STORE_EVENT_VIDEO_URL_REQUEST = 'STORE_EVENT_VIDEO_URL_REQUEST'
export const STORE_EVENT_VIDEO_URL_SUCCESS = 'STORE_EVENT_VIDEO_URL_SUCCESS'
export const STORE_EVENT_VIDEO_URL_FAILURE = 'STORE_EVENT_VIDEO_URL_FAILURE'
export const STORE_EVENT_SNAPSHOT_URLS_REQUEST = 'STORE_EVENT_SNAPSHOT_URLS_REQUEST'
export const STORE_EVENT_SNAPSHOT_URLS_SUCCESS = 'STORE_EVENT_SNAPSHOT_URLS_SUCCESS'
export const STORE_EVENT_SNAPSHOT_URLS_FAILURE = 'STORE_EVENT_SNAPSHOT_URLS_FAILURE'

export const CLEAR_EVENT_VIEW_INFO = 'CLEAR_EVENT_VIEW_INFO'
export const IS_FINISH_STORE_EVENT_VIDEO_AND_SNAPSHOTS_ROUTINE = 'IS_FINISH_STORE_EVENT_VIDEO_AND_SNAPSHOTS_ROUTINE'

///////////////
// Agreement //
///////////////
export const GET_LAST_AGREEMENT_INFO_REQUEST = 'GET_LAST_AGREEMENT_INFO_REQUEST'
export const GET_LAST_AGREEMENT_INFO_SUCCESS = 'GET_LAST_AGREEMENT_INFO_SUCCESS'
export const GET_LAST_AGREEMENT_INFO_FAILURE = 'GET_LAST_AGREEMENT_INFO_FAILURE'

export const GET_AGREEMENT_REQUEST = 'GET_AGREEMENT_REQUEST'
export const GET_AGREEMENT_SUCCESS = 'GET_AGREEMENT_SUCCESS'
export const GET_AGREEMENT_FAILURE = 'GET_AGREEMENT_FAILURE'

export const UPDATE_AGREEMENT_REQUEST = 'UPDATE_AGREEMENT_REQUEST'
export const UPDATE_AGREEMENT_SUCCESS = 'UPDATE_AGREEMENT_SUCCESS'
export const UPDATE_AGREEMENT_FAILURE = 'UPDATE_AGREEMENT_FAILURE'

////////////
// Native //
////////////
export const IS_APP_ACTIVE = 'IS_APP_ACTIVE'
export const IS_APP_USE = 'IS_APP_USE'
export const IS_FULLSCREEN = 'IS_FULLSCREEN'
export const CURRENT_APP_ORIENTATION_TYPE = 'CURRENT_APP_ORIENTATION_TYPE'
export const IS_NETWORK_CONNECTED = 'IS_NETWORK_CONNECTED'
export const IS_SCREEN_ORIENTATION_LOCK = 'IS_SCREEN_ORIENTATION_LOCK'

/////////////////////
// Arming Schedule //
/////////////////////
export const UPDATE_SITE_ARMING_SCHEDULE_ENABLED_REQUEST = 'UPDATE_SITE_ARMING_SCHEDULE_ENABLED_REQUEST'
export const UPDATE_SITE_ARMING_SCHEDULE_ENABLED_SUCCESS = 'UPDATE_SITE_ARMING_SCHEDULE_ENABLED_SUCCESS'
export const UPDATE_SITE_ARMING_SCHEDULE_ENABLED_FAILURE = 'UPDATE_SITE_ARMING_SCHEDULE_ENABLED_FAILURE'

export const GET_SITE_ARMING_SCHEDULES_REQUEST = 'GET_SITE_ARMING_SCHEDULES_REQUEST'
export const GET_SITE_ARMING_SCHEDULES_SUCCESS = 'GET_SITE_ARMING_SCHEDULES_SUCCESS'
export const GET_SITE_ARMING_SCHEDULES_FAILURE = 'GET_SITE_ARMING_SCHEDULES_FAILURE'

export const CREATE_SITE_ARMING_SCHEDULE_REQUEST = 'CREATE_SITE_ARMING_SCHEDULE_REQUEST'
export const CREATE_SITE_ARMING_SCHEDULE_SUCCESS = 'CREATE_SITE_ARMING_SCHEDULE_SUCCESS'
export const CREATE_SITE_ARMING_SCHEDULE_FAILURE = 'CREATE_SITE_ARMING_SCHEDULE_FAILURE'

export const UPDATE_SITE_ARMING_SCHEDULE_REQUEST = 'UPDATE_SITE_ARMING_SCHEDULE_REQUEST'
export const UPDATE_SITE_ARMING_SCHEDULE_SUCCESS = 'UPDATE_SITE_ARMING_SCHEDULE_SUCCESS'
export const UPDATE_SITE_ARMING_SCHEDULE_FAILURE = 'UPDATE_SITE_ARMING_SCHEDULE_FAILURE'

export const DELETE_SITE_ARMING_SCHEDULE_REQUEST = 'DELETE_SITE_ARMING_SCHEDULE_REQUEST'
export const DELETE_SITE_ARMING_SCHEDULE_SUCCESS = 'DELETE_SITE_ARMING_SCHEDULE_SUCCESS'
export const DELETE_SITE_ARMING_SCHEDULE_FAILURE = 'DELETE_SITE_ARMING_SCHEDULE_FAILURE'

//////////////////
// Member Roles //
//////////////////
export const GET_MEMBER_ROLES_REQUEST = 'GET_MEMBER_ROLES_REQUEST'
export const GET_MEMBER_ROLES_SUCCESS = 'GET_MEMBER_ROLES_SUCCESS'
export const GET_MEMBER_ROLES_FAILURE = 'GET_MEMBER_ROLES_FAILURE'


////////////
// Webrtc //
////////////
export const CREATE_WEBRTC_ICE_SERVERS_REQUEST = 'CREATE_WEBRTC_ICE_SERVERS_REQUEST'
export const CREATE_WEBRTC_ICE_SERVERS_SUCCESS = 'CREATE_WEBRTC_ICE_SERVERS_SUCCESS'
export const CREATE_WEBRTC_ICE_SERVERS_FAILURE = 'CREATE_WEBRTC_ICE_SERVERS_FAILURE'

export const UPDATE_WEBRTC_AUDIO_STATE = 'UPDATE_WEBRTC_AUDIO_STATE'
export const UPDATE_WEBRTC_AUDIO_SENDER_STATE = 'UPDATE_WEBRTC_AUDIO_SENDER_STATE'

export const STORE_WEBRTC_AUDIO_CONTEXT = 'STORE_WEBRTC_AUDIO_CONTEXT'
export const STORE_WEBRTC_AUDIO_SENDER_CONTEXT = 'STORE_WEBRTC_AUDIO_SENDER_CONTEXT'

export const REMOVE_WEBRTC_VIDEO_INFO = 'REMOVE_WEBRTC_VIDEO_INFO'

export const SET_WEBRTC_VIDEO_LOG = 'SET_WEBRTC_VIDEO_LOG'
export const REMOVE_WEBRTC_VIDEO_LOGS = 'REMOVE_WEBRTC_VIDEO_LOGS'
export const SET_WEBRTC_VIDEO_DETAILS = 'SET_WEBRTC_VIDEO_DETAILS'
export const SET_WEBRTC_VIDEO_DETAILS_TO_HTML = 'SET_WEBRTC_VIDEO_DETAILS_TO_HTML'
export const SET_CURRENT_PAGE_DEVICE_ID = 'SET_CURRENT_PAGE_DEVICE_ID'


/////////////
// Support //
/////////////
export const INIT_ALL_SUPPORT_VERSION = 'INIT_ALL_SUPPORT_VERSION'

///////////
// Proxy //
///////////
export const GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_REQUEST = 'GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_REQUEST'
export const GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_SUCCESS = 'GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_SUCCESS'
export const GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_FAILURE = 'GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_FAILURE'

export const GET_SITE_ONVIF_REQUEST_LIST_FROM_PROXY_REQUEST = 'GET_SITE_ONVIF_REQUEST_LIST_FROM_PROXY_REQUEST'
export const GET_SITE_ONVIF_REQUEST_LIST_FROM_PROXY_SUCCESS = 'GET_SITE_ONVIF_REQUEST_LIST_FROM_PROXY_SUCCESS'
export const GET_SITE_ONVIF_REQUEST_LIST_FROM_PROXY_FAILURE = 'GET_SITE_ONVIF_REQUEST_LIST_FROM_PROXY_FAILURE'

export const GET_SITE_ONVIF_CAPABILITIES_FROM_PROXY_REQUEST = 'GET_SITE_ONVIF_CAPABILITIES_FROM_PROXY_REQUEST'
export const GET_SITE_ONVIF_CAPABILITIES_FROM_PROXY_SUCCESS = 'GET_SITE_ONVIF_CAPABILITIES_FROM_PROXY_SUCCESS'
export const GET_SITE_ONVIF_CAPABILITIES_FROM_PROXY_FAILURE = 'GET_SITE_ONVIF_CAPABILITIES_FROM_PROXY_FAILURE'

export const GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_REQUEST = 'GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_REQUEST'
export const GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_SUCCESS = 'GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_SUCCESS'
export const GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_FAILURE = 'GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_FAILURE'

export const SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_REQUEST = 'SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_REQUEST'
export const SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_SUCCESS = 'SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_SUCCESS'
export const SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_FAILURE = 'SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_FAILURE'

export const SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_REQUEST = 'SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_REQUEST'
export const SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_SUCCESS = 'SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_SUCCESS'
export const SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_FAILURE = 'SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_FAILURE'

export const GET_ONVIF_TEMPLATE_FROM_PROXY_REQUEST = 'GET_ONVIF_TEMPLATE_FROM_PROXY_REQUEST'
export const GET_ONVIF_TEMPLATE_FROM_PROXY_SUCCESS = 'GET_ONVIF_TEMPLATE_FROM_PROXY_SUCCESS'
export const GET_ONVIF_TEMPLATE_FROM_PROXY_FAILURE = 'GET_ONVIF_TEMPLATE_FROM_PROXY_FAILURE'

export const GET_SITE_ONVIF_RELAY_OUTPUTS_OPTIONS_FROM_PROXY_REQUEST = 'GET_SITE_ONVIF_RELAY_OUTPUTS_OPTIONS_FROM_PROXY_REQUEST'
export const GET_SITE_ONVIF_RELAY_OUTPUTS_OPTIONS_FROM_PROXY_SUCCESS = 'GET_SITE_ONVIF_RELAY_OUTPUTS_OPTIONS_FROM_PROXY_SUCCESS'
export const GET_SITE_ONVIF_RELAY_OUTPUTS_OPTIONS_FROM_PROXY_FAILURE = 'GET_SITE_ONVIF_RELAY_OUTPUTS_OPTIONS_FROM_PROXY_FAILURE'

export const RESET_SITE_CAMERAS_CAPABILITIES_FROM_PROXY = 'RESET_SITE_CAMERAS_CAPABILITIES_FROM_PROXY'
export const RESET_SITE_ONVIFS_FROM_PROXY = 'RESET_SITE_ONVIFS_FROM_PROXY'
export const RESET_SITE_ONVIF_RELAY_FROM_PROXY = 'RESET_SITE_ONVIF_RELAY_FROM_PROXY'

export const SET_RELAY_FINISH_TIME = 'SET_RELAY_FINISH_TIME'

export const GET_SITE_CAMERA_CAPABILITIES_FROM_PROXY_REQUEST = 'GET_SITE_CAMERA_CAPABILITIES_FROM_PROXY_REQUEST'
export const GET_SITE_CAMERA_CAPABILITIES_FROM_PROXY_SUCCESS = 'GET_SITE_CAMERA_CAPABILITIES_FROM_PROXY_SUCCESS'
export const GET_SITE_CAMERA_CAPABILITIES_FROM_PROXY_FAILURE = 'GET_SITE_CAMERA_CAPABILITIES_FROM_PROXY_FAILURE'

export const GET_SITE_CAMERA_ONVIF_PROFILES_FROM_PROXY_REQUEST = 'GET_SITE_CAMERA_ONVIF_PROFILES_FROM_PROXY_REQUEST'
export const GET_SITE_CAMERA_ONVIF_PROFILES_FROM_PROXY_SUCCESS = 'GET_SITE_CAMERA_ONVIF_PROFILES_FROM_PROXY_SUCCESS'
export const GET_SITE_CAMERA_ONVIF_PROFILES_FROM_PROXY_FAILURE = 'GET_SITE_CAMERA_ONVIF_PROFILES_FROM_PROXY_FAILURE'

export const SET_SITE_CAMERA_ONVIF_PROFILE_INDEX = 'SET_SITE_CAMERA_ONVIF_PROFILE_INDEX'
export const ADD_INFO_TO_SITE_CAMERA_STREAM_CAPABILITIES = 'ADD_INFO_TO_SITE_CAMERA_STREAM_CAPABILITIES'

///////////////
// Proxy PTZ //
///////////////
export const GET_SITE_ONVIF_PTZ_CONFIGS_FROM_PROXY_REQUEST = 'GET_SITE_ONVIF_PTZ_CONFIGS_FROM_PROXY_REQUEST'
export const GET_SITE_ONVIF_PTZ_CONFIGS_FROM_PROXY_SUCCESS = 'GET_SITE_ONVIF_PTZ_CONFIGS_FROM_PROXY_SUCCESS'
export const GET_SITE_ONVIF_PTZ_CONFIGS_FROM_PROXY_FAILURE = 'GET_SITE_ONVIF_PTZ_CONFIGS_FROM_PROXY_FAILURE'

export const GET_SITE_ONVIF_PTZ_STATUS_FROM_PROXY_REQUEST = 'GET_SITE_ONVIF_PTZ_STATUS_FROM_PROXY_REQUEST'
export const GET_SITE_ONVIF_PTZ_STATUS_FROM_PROXY_SUCCESS = 'GET_SITE_ONVIF_PTZ_STATUS_FROM_PROXY_SUCCESS'
export const GET_SITE_ONVIF_PTZ_STATUS_FROM_PROXY_FAILURE = 'GET_SITE_ONVIF_PTZ_STATUS_FROM_PROXY_FAILURE'

export const SET_SITE_ONVIF_RELATIVE_PTZ_FROM_PROXY_REQUEST = 'SET_SITE_ONVIF_RELATIVE_PTZ_FROM_PROXY_REQUEST'
export const SET_SITE_ONVIF_RELATIVE_PTZ_FROM_PROXY_SUCCESS = 'SET_SITE_ONVIF_RELATIVE_PTZ_FROM_PROXY_SUCCESS'
export const SET_SITE_ONVIF_RELATIVE_PTZ_FROM_PROXY_FAILURE = 'SET_SITE_ONVIF_RELATIVE_PTZ_FROM_PROXY_FAILURE'

export const SET_SITE_ONVIF_CONTINUOUS_PTZ_FROM_PROXY_REQUEST = 'SET_SITE_ONVIF_CONTINUOUS_PTZ_FROM_PROXY_REQUEST'
export const SET_SITE_ONVIF_CONTINUOUS_PTZ_FROM_PROXY_SUCCESS = 'SET_SITE_ONVIF_CONTINUOUS_PTZ_FROM_PROXY_SUCCESS'
export const SET_SITE_ONVIF_CONTINUOUS_PTZ_FROM_PROXY_FAILURE = 'SET_SITE_ONVIF_CONTINUOUS_PTZ_FROM_PROXY_FAILURE'

export const SET_SITE_ONVIF_STOP_PTZ_FROM_PROXY_REQUEST = 'SET_SITE_ONVIF_STOP_PTZ_FROM_PROXY_REQUEST'
export const SET_SITE_ONVIF_STOP_PTZ_FROM_PROXY_SUCCESS = 'SET_SITE_ONVIF_STOP_PTZ_FROM_PROXY_SUCCESS'
export const SET_SITE_ONVIF_STOP_PTZ_FROM_PROXY_FAILURE = 'SET_SITE_ONVIF_STOP_PTZ_FROM_PROXY_FAILURE'

export const SET_SITE_ONVIF_ABSOLUTE_PTZ_FROM_PROXY_REQUEST = 'SET_SITE_ONVIF_ABSOLUTE_PTZ_FROM_PROXY_REQUEST'
export const SET_SITE_ONVIF_ABSOLUTE_PTZ_FROM_PROXY_SUCCESS = 'SET_SITE_ONVIF_ABSOLUTE_PTZ_FROM_PROXY_SUCCESS'
export const SET_SITE_ONVIF_ABSOLUTE_PTZ_FROM_PROXY_FAILURE = 'SET_SITE_ONVIF_ABSOLUTE_PTZ_FROM_PROXY_FAILURE'

export const SET_SITE_ONVIF_PTZ_GO_TO_HOME_FROM_PROXY_REQUEST = 'SET_SITE_ONVIF_PTZ_GO_TO_HOME_FROM_PROXY_REQUEST'
export const SET_SITE_ONVIF_PTZ_GO_TO_HOME_FROM_PROXY_SUCCESS = 'SET_SITE_ONVIF_PTZ_GO_TO_HOME_FROM_PROXY_SUCCESS'
export const SET_SITE_ONVIF_PTZ_GO_TO_HOME_FROM_PROXY_FAILURE = 'SET_SITE_ONVIF_PTZ_GO_TO_HOME_FROM_PROXY_FAILURE'

export const SET_SITE_ONVIF_PTZ_SET_TO_HOME_FROM_PROXY_REQUEST = 'SET_SITE_ONVIF_PTZ_SET_TO_HOME_FROM_PROXY_REQUEST'
export const SET_SITE_ONVIF_PTZ_SET_TO_HOME_FROM_PROXY_SUCCESS = 'SET_SITE_ONVIF_PTZ_SET_TO_HOME_FROM_PROXY_SUCCESS'
export const SET_SITE_ONVIF_PTZ_SET_TO_HOME_FROM_PROXY_FAILURE = 'SET_SITE_ONVIF_PTZ_SET_TO_HOME_FROM_PROXY_FAILURE'

export const GET_SITE_ONVIF_PTZ_PRESETS_FROM_PROXY_REQUEST = 'GET_SITE_ONVIF_PTZ_PRESETS_FROM_PROXY_REQUEST'
export const GET_SITE_ONVIF_PTZ_PRESETS_FROM_PROXY_SUCCESS = 'GET_SITE_ONVIF_PTZ_PRESETS_FROM_PROXY_SUCCESS'
export const GET_SITE_ONVIF_PTZ_PRESETS_FROM_PROXY_FAILURE = 'GET_SITE_ONVIF_PTZ_PRESETS_FROM_PROXY_FAILURE'

export const SET_SITE_ONVIF_PTZ_GO_TO_PRESET_FROM_PROXY_REQUEST = 'SET_SITE_ONVIF_PTZ_GO_TO_PRESET_FROM_PROXY_REQUEST'
export const SET_SITE_ONVIF_PTZ_GO_TO_PRESET_FROM_PROXY_SUCCESS = 'SET_SITE_ONVIF_PTZ_GO_TO_PRESET_FROM_PROXY_SUCCESS'
export const SET_SITE_ONVIF_PTZ_GO_TO_PRESET_FROM_PROXY_FAILURE = 'SET_SITE_ONVIF_PTZ_GO_TO_PRESET_FROM_PROXY_FAILURE'

export const SET_CAMERA_PTZ_AREA_ZOOM_REQUEST = 'SET_CAMERA_PTZ_AREA_ZOOM_REQUEST'
export const SET_CAMERA_PTZ_AREA_ZOOM_SUCCESS = 'SET_CAMERA_PTZ_AREA_ZOOM_SUCCESS'
export const SET_CAMERA_PTZ_AREA_ZOOM_FAILURE = 'SET_CAMERA_PTZ_AREA_ZOOM_FAILURE'

///////////////////
// Proxy Speaker //
///////////////////
export const GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_REQUEST = 'GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_REQUEST'
export const GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_SUCCESS = 'GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_SUCCESS'
export const GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_FAILURE = 'GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_FAILURE'

export const GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_REQUEST = 'GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_REQUEST'
export const GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_SUCCESS = 'GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_SUCCESS'
export const GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_FAILURE = 'GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_FAILURE'


export const SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_REQUEST = 'SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_REQUEST'
export const SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_SUCCESS = 'SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_SUCCESS'
export const SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_FAILURE = 'SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_FAILURE'

/////////////////////////
///// CHECK_SESSION /////
/////////////////////////

export const AUTH_CHECK_SESSION_REQUEST = 'AUTH_CHECK_SESSION_REQUEST'
export const AUTH_CHECK_SESSION_SUCCESS = 'AUTH_CHECK_SESSION_SUCCESS'
export const AUTH_CHECK_SESSION_FAILURE = 'AUTH_CHECK_SESSION_FAILURE'

//////////////////////////////////////
///// AUTH_HANDLE_AUTHENTICATION /////
//////////////////////////////////////

export const AUTH_HANDLE_AUTHENTICATION_REQUEST = 'AUTH_HANDLE_AUTHENTICATION_REQUEST'
export const AUTH_HANDLE_AUTHENTICATION_SUCCESS = 'AUTH_HANDLE_AUTHENTICATION_SUCCESS'
export const AUTH_HANDLE_AUTHENTICATION_FAILURE = 'AUTH_HANDLE_AUTHENTICATION_FAILURE'

/////////////////////
// Proxy Recording //
/////////////////////
export const GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_REQUEST = 'GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_REQUEST'
export const GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_SUCCESS = 'GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_SUCCESS'
export const GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_FAILURE = 'GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_FAILURE'

export const SET_RECORDING_TIME_INTERVAL = 'SET_RECORDING_TIME_INTERVAL'
export const SET_ONVIF_PLAYBACK_INFO = 'SET_ONVIF_PLAYBACK_INFO'
export const SET_PLAYBACK_JUMP_TO = 'SET_PLAYBACK_JUMP_TO'

//////////////////////
// incident reports //
//////////////////////
export const GET_SITE_INCIDENT_REPORTS_REQUEST = 'GET_SITE_INCIDENT_REPORTS_REQUEST'
export const GET_SITE_INCIDENT_REPORTS_SUCCESS = 'GET_SITE_INCIDENT_REPORTS_SUCCESS'
export const GET_SITE_INCIDENT_REPORTS_FAILURE = 'GET_SITE_INCIDENT_REPORTS_FAILURE'
export const GET_SITE_INCIDENT_REPORT_LOGS_REQUEST = 'GET_SITE_INCIDENT_REPORT_LOGS_REQUEST'
export const GET_SITE_INCIDENT_REPORT_LOGS_SUCCESS = 'GET_SITE_INCIDENT_REPORT_LOGS_SUCCESS'
export const GET_SITE_INCIDENT_REPORT_LOGS_FAILURE = 'GET_SITE_INCIDENT_REPORT_LOGS_FAILURE'
export const GET_SITE_INCIDENT_REPORT_DOWNLOAD_URL_REQUEST = 'GET_SITE_INCIDENT_REPORT_DOWNLOAD_URL_REQUEST'
export const GET_SITE_INCIDENT_REPORT_DOWNLOAD_URL_SUCCESS = 'GET_SITE_INCIDENT_REPORT_DOWNLOAD_URL_SUCCESS'
export const GET_SITE_INCIDENT_REPORT_DOWNLOAD_URL_FAILURE = 'GET_SITE_INCIDENT_REPORT_DOWNLOAD_URL_FAILURE'

